import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
import { mix } from "@theme-ui/color";
import { ContrastRatio } from "../../src/features/gadgets-for-notes/ContrastRatio";
import { NextColorModeButton } from "../../src/features/application-ui/NextColorModeButton";
export const Flip = () => <NextColorModeButton sx={{
  bg: "muted",
  px: 1,
  border: "1px solid",
  borderColor: "mutedPrimary09"
}} mdxType="NextColorModeButton">
    flip
  </NextColorModeButton>;
export const _frontmatter = {};
const layoutProps = {
  Flip,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`When we remove underlines from our HTML links, we need to make sure that
everyone can tell what pieces of text are links.`}</p>
    <p>{`The links must have sufficient contrast to background color to be readable,
and they also must have sufficient contrast ratio to the text color to be
noticeable.`}</p>
    <p>{`Per `}<a parentName="p" {...{
        "href": "/notes/wcag20-guideline-1-4-distinguishable",
        "title": "WCAG20 Guideline 1.4 Distinguishable"
      }}>{`WCAG20 Guideline 1.4 Distinguishable`}</a>{` both the text and links must
have a contrast ration of 4.5:1, or 3:1 if it’s large scale text, and
respectively 7:1 and 4.5:1 for Level AAA conformance. This is old news,
though.`}</p>
    <p>{`We can use lightness difference `}<strong parentName="p">{`≥3:1`}</strong>{` with surrounding text to make them
a bit more distinguishable.`}</p>
    <p>{`Contrast ratios in this color mode are:`}</p>
    <ul>
      <li parentName="ul">{`Between links and background: `}<ContrastRatio colorNames={["primary", "background"]} mdxType="ContrastRatio" /></li>
      <li parentName="ul">{`Between links and text: `}<ContrastRatio colorNames={["primary", "text"]} mdxType="ContrastRatio" /></li>
      <li parentName="ul">{`Between text and background: `}<ContrastRatio colorNames={["text", "background"]} mdxType="ContrastRatio" /></li>
    </ul>

    <p>{`If you `}<Flip mdxType="Flip" />{` through the color modes here (top right corner), you’ll
notice that links in `}<em parentName="p">{`“soft”`}</em>{` would be fine without underlines, but we can’t
say that about links in my other color modes.`}</p>
    <p>{`I added underlines back to this site while writing this note 😅`}</p>
    <h2 {...{
      "id": "see-more",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-more",
        "aria-label": "see more permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`See more`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://contrast-triangle.com/"
          }}>{`The Contrast Triangle app`}</a>{` by
`}<a parentName="p" {...{
            "href": "https://chipcullen.com/"
          }}>{`Chip Cullen`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/2008/NOTE-WCAG20-TECHS-20081211/G183"
          }}>{`W3C Technique G183`}</a>{`.`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote">{`G183: Using a contrast ratio of 3:1 with surrounding text and providing
additional visual cues on focus for links or controls where color alone
is used to identify them`}</p>
        </blockquote>
        <blockquote parentName="li">
          <p parentName="blockquote">{`… relative luminance of color alone may not be obvious to people with
black/white color blindness. If there are not a large number of links in
the block of text, underlines are recommended for links.`}</p>
        </blockquote>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.w3.org/TR/2008/NOTE-WCAG20-TECHS-20081211/working-examples/G183/link-contrast.html"
          }}>{`List of colors that would provide 3:1 contrast with black words and 4.5:1 contrast with a white background`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      